import request from './request'
import { BASE_URL } from './url_config'

export function getStatisticFlow(v,st,et) {
  // return a
  return request({
    method: 'GET',
    url: '/statistics/App',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et
    }
  })
}

export function getStatisticAllFlow(v,st,et) {
  // return a
  return request({
    method: 'GET',
    url: '/statistics/all_flow',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et
    }
  })
}

export function getAppIpCount(v,st,et) {
  // return a
  return request({
    method: 'GET',
    url: '/statistics/byte',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et
    }
  })
}

export function getProtocolLine(v,st,et) {
  // return a
  return request({
    method: 'GET',
    url: '/statistics/Protocol',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et
    }
  })
}


export function getAppInfoById(v,ip,st,et,baseURL=BASE_URL) {
  // return a
  return request({
    method: 'GET',
    url: '/statistics/client',
    params:{
      v,
      ip,
      st,
      et,
    },
    baseURL
  })
}


export function getTopInfo(v,st,et) {
  return request({
    method: 'GET',
    url: '/get_top_info',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et 
    }
  })
}

export function getFlowDetailTable(v,st,et,app_name,ip,baseURL=BASE_URL) {
  // // console.debug(v,st,et,app_name,ip) 
  return request({
    methods: 'GET',
    url: '/get_flow_detail/table',
    
    params:{
      v,
      st,
      et,
      app_name,
      ip,
      // type,
      tz_offset:new Date().getTimezoneOffset()
    },
    baseURL
  })
}

export function getFlowDetailLine(v,st,et,app_name,ip,baseURL=BASE_URL) {
  return request({
    methods: 'GET',
    url: '/get_flow_detail/line',
    params:{
      v,
      st,
      et,
      app_name,
      ip,
    },
    baseURL
  })
}

export function getOneWayFlow(v,st,et) {
  return request({
    methods: 'GET',
    url: '/get_one_way_flow',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et,
    }
  })
}


export function getOneWayFlowTableData(v,st,et) {
  return request({
    methods: 'GET',
    url: '/get_one_way_flow/table',
    baseURL:BASE_URL,
    params:{
      v,
      st,
      et,
    }
  })
}